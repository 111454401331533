export const getUser = () => {
    const userStr = sessionStorage.getItem("email");
    if (userStr) return userStr;
    else return null;
}

export const getToken = () => {
    return sessionStorage.getItem("key") || null
}

export const setUserSession = (key, email, password) => {
    sessionStorage.setItem("key", key)
    sessionStorage.setItem("email", email)
    sessionStorage.setItem("password", password)
}

export const removeUserSession = () => {
    sessionStorage.removeItem("key")
    sessionStorage.removeItem("email")
    sessionStorage.removeItem("password")
}